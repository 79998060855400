body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}


.description-cell {
  position: relative;
  max-height: fit-content; 
  overflow: hidden;
  width: 200px;
}

.truncate-text {
  white-space: pre-wrap; /* Allow line breaks */
}

.read-more-link {
  position: absolute;
  bottom: -10px;
  background: #fff;
  padding: 4px 8px;
  border: 1px solid #1890ff;
  color: #1890ff;
  border-radius: 2px;
  margin-top: 10px;
  text-decoration: none;
  width: 100%;
}

.read-more-link:hover {
  background: #1890ff;
  color: #fff;
}
.description-cell.expanded{
  max-height: none;
}

/* custom input to hide default mm/dd/yyy in input */

.custom-input::-webkit-input-placeholder {
  color: transparent;
}

.custom-input:-moz-placeholder {
  color: transparent;
}

.custom-input::-moz-placeholder {
  color: transparent;
}

.custom-input:-ms-input-placeholder {
  color: transparent;
}
.custom-modal .ant-modal-wrap {
  background-color: rgba(241, 240, 240, 0.8);
}

.customDelete .ant-modal-content{
  background-color: #430A09 !important;
}
.ant-collapse-arrow > svg {
  color: rgb(54, 181, 54); /* Replace with your desired color */
}