.deleteObjectDialogBox h2 {
  background: red;
  color: white;
  padding: 0.5rem;
  font-size: 1.2rem;
}
.deleteObjectDialogBox .deleteObjectDialogBoxBody {
  padding: 0.5rem;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  gap: 1rem;
  min-height: 2rem;
}

.manageDeptItemAccordionButton {
  flex-grow: 1;
  display: grid !important;
  grid-template-columns: repeat(auto-fit, minmax(7.5rem, auto));
  align-items: center;
  justify-content: flex-end;
  gap: 0.2rem;
  margin-right: 1.2rem;
}

.imageStyle {
  width: '200px'; // Set the width you desire
  height: '200px'; // Set the height you desire
}
